<template>
  <header id="header" class="font-bold border-b border-gray flex fixed w-full items-center px-4 bg-white z-40">
    <div v-if="header.type === 'profile.show'" class="flex items-center w-full uppercase">
      <div @click="goBackExplore" class="flex-none cursor-pointer">
        <i class="icon-arrowLeft pr-small" />
        <p class="inline-block">
          {{ 'Atrás' }}
        </p>
      </div>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'single'" class="flex items-center uppercase">
      <div @click="goBack" class="flex-none cursor-pointer">
        <i class="icon-arrowLeft pr-small" />
        <p class="inline-block">
          {{ header.title }}
        </p>
      </div>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'explore'" class="flex uppercase space-x-2 text-gray w-full">
      <div class="flex-auto items-start text-left">
        <router-link active-class="border-b-2 border-primary text-primary"
                     to="/explore"
                     class="inline-block py-4">
          Explorar
        </router-link>
        <router-link active-class="border-b-2 border-primary text-primary"
                     v-if="user.isRestaurant"
                     to="/interested"
                     class="inline-block py-4 ml-small">
          interesados
        </router-link>
        <router-link active-class="border-b-2 border-primary text-primary"
                     to="/favorites"
                     class="inline-block py-4 ml-small">
          solicitados
        </router-link>
      </div>
      <div v-if="user.isRestaurant" class="flex-none items-end text-right">
        <i @click="multiSelection"
           class="icon-group text-gray inline-block py-4 text-left cursor-pointer"
           :class="multi ? 'text-dgray' : 'text-gray'" title="Seleccion multiple"></i>
      </div>
      <div class="flex-none items-end text-right pl-normal">
        <i @click="activeSearch"
           class="icon-search text-gray inline-block py-4 text-left cursor-pointer"
           :class="search ? 'text-dgray' : 'text-gray'" title="Búsqueda"></i>
      </div>
    </div>
    <div @click="goBack" v-if="header.type === 'explore.show'" class="flex items-center w-full cursor-pointer flex items-center">
      <div class="flex-none flex items-center">
        <i class="icon-arrowLeft pr-small align-middle text-lg" />
        <div class="flex flex-col items-start justify-center align-middle py-3 space-y-1">
          ATRÁS
<!--          <p class="block capitalize leading-none">-->
<!--            {{ values.name || '&nbsp;' }}-->
<!--          </p>-->
<!--          <p v-if="values.ig_username !== null" class="block text-gray font-light leading-none">-->
<!--            {{ '@' + values.ig_username || '&nbsp;' }}-->
<!--          </p>-->
        </div>
      </div>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'collaboration'" class="uppercase space-x-2 text-gray">
      <router-link active-class="border-b-2 border-black text-black"
                   to="/collaborations"
                   class="inline-block py-4">
        Colaboraciones
      </router-link>
      <router-link active-class="border-b-2 border-black text-black"
                   to="/private"
                   class="inline-block py-4">
        privadas
      </router-link>
<!--      <p @click="tabPublic" class="inline-block pr-sm cursor-pointer" :class="{ active: isPublic }">Colaboraciones</p>-->
<!--      <p @click="tabPrivate" class="inline-block pr-sm cursor-pointer" :class="{ active: isPrivate }">privadas</p>-->
    </div>
    <div @click="goBack" v-if="header.type === 'collaboration.create'" class="cursor-pointer">
      <div class="cursor-pointer">
        <i class="icon-arrowLeft pr-small"></i><p class="inline-block uppercase">{{ header.title }}</p>
      </div>
    </div>
    <div @click="goBack" v-if="header.type === 'collaboration.create.multi'" class="cursor-pointer">
      <div class="cursor-pointer">
        <i class="icon-arrowLeft pr-small"></i><p class="inline-block uppercase">{{ header.title }}</p>
      </div>
    </div>
    <div @click="goBackPublic" v-if="header.type === 'collaboration.create.public'" class="flex items-center cursor-pointer w-full">
      <div class="flex-none cursor-pointer">
        <i class="icon-arrowLeft pr-small"></i><p class="inline-block uppercase">{{ header.title }}</p>
      </div>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'collaboration.show'" class="flex items-center w-full cursor-pointer">
      <div @click="goBack" class="flex-none">
        <div class="cursor-pointer">
          <i class="icon-arrowLeft pr-small"></i>
          Atrás
<!--          <div v-if="values.foodies.length === 1" class="inline-block uppercase">-->
<!--            <p  class="inline-block break-normal">-->
<!--              {{ `Colab ${values.foodies[0].ig_username !== null ? '@' + values.foodies[0].ig_username : values.foodies[0].name} -` }}-->
<!--            </p>-->
<!--            <p class="inline-block text-primary ml-1">-->
<!--              {{ `${values.code}` }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <div v-else-if="values.foodies.length > 1" class="inline-block uppercase">-->
<!--            <p  class="inline-block">-->
<!--              {{ `Colab ${values.foodies[0].ig_username !== null ? '@' + values.foodies[0].ig_username : values.foodies[0].name} y otros -` }}-->
<!--            </p>-->
<!--            <p class="inline-block text-primary ml-1">-->
<!--              {{ `${values.code}` }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <div v-else-if="values.foodies.length === 0" class="inline-block uppercase">-->
<!--            <p  class="inline-block">-->
<!--              {{ `Colab privada - ` }}-->
<!--            </p>-->
<!--            <p class="inline-block text-primary ml-1">-->
<!--              {{ `${values.code}` }}-->
<!--            </p>-->
<!--          </div>-->
        </div>
      </div>
<!--      <div v-else @click="goBack" class="flex-none">-->
<!--        <i class="icon-arrowLeft pr-small"></i>-->
<!--        <p class="inline-block">-->
<!--          {{ 'Colaboracion abierta - ' }}-->
<!--        </p>-->
<!--        <p class="inline-block text-primary ml-1">-->
<!--          {{ values.code }}-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'notifications'" class="flex uppercase space-x-2 text-gray w-full items-center">
      <router-link active-class="flex-none border-b-2 border-black text-black text-left"
                   to="/notifications"
                   class="inline-block py-4 inline-block">
        Notificaciones
      </router-link>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
    <div v-if="header.type === 'historial'" class="flex uppercase space-x-2 text-gray w-full items-center">
      <router-link active-class="flex-none border-b-2 border-black text-black text-left"
                   to="/historial"
                   class="inline-block py-4 inline-block">
        Historial
      </router-link>
<!--      <div class="flex-auto text-right">-->
<!--        <a-logo-switch type="1" class="w-20 select-none lowercase inline-block"/>-->
<!--      </div>-->
    </div>
<!--    <div>{{ getReturn }}</div>-->
  </header>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Header',
  props: {
    values: Object,
    header: Object
  },
  emits: ['multiSelection', 'search'],
  data () {
    return {
      isPublic: true,
      isPrivate: false,
      multi: false,
      search: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    goBackExplore () {
      this.$router.push({ path: '/explore' })
    },
    goBack () {
      this.$router.go(-1)
    },
    goBackPublic () {
      this.$router.push({ path: '/collaborations' })
    },
    multiSelection () {
      this.multi = !this.multi
      this.search = false
      this.$emit('multiSelection', this.multi, this.search)
    },
    activeSearch () {
      this.search = !this.search
      this.multi = false
      this.$emit('search', this.search, this.multi)
    }
  }
}

</script>

<style scoped>
header {
  min-height: 56px;
}
.active {
  @apply text-dgray
}
</style>
